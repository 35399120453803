<template>
  <b-container>
    <b-row>
      <b-col>
        <b-form-group label="Select Date">
          <b-form-datepicker
            v-model="date"
            :max="maxDate"
            :min="minDate"
            :disabled-dates="disabledDates"
            :locale="locale"
            :date-info-fn="dateClass"
            value-as-date
            hide-header
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row
      v-if="date"
      class="mt-1"
    >

      <b-col lg="5">
        <app-collapse
          accordion
          type="border"
        >
          <app-collapse-item
            class="shadow-none"
            title="Add Slot"
          >
            <b-row>
              <b-col cols="auto">
                <b-form-group label="Start Time">
                  <v-date-picker
                    v-model="begindatetimestamp"
                    mode="time"
                    :minute-increment="5"
                    color="purple"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="auto">
                <b-form-group label="End Time">
                  <v-date-picker
                    v-model="enddatetimestamp"
                    mode="time"
                    :minute-increment="5"
                    color="purple"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="auto"
                class="my-auto"
              >
                <b-button
                  variant="primary"
                  @click="addSlot"
                >
                  <feather-icon icon="PlusIcon" />
                  Add
                </b-button>
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item

            class="shadow-none"
            title="Bulk Add Slots"
          >

            <!--            Take start time, end time from user, duration of slots, time between slots -->

            <b-row>
              <b-col lg="12">
                <b-form-group label="Duration (in Minutes)">
                  <b-form-input
                    v-model="bulkSlot.duration"
                    type="number"
                    min="1"
                    placeholder="Duration of each slot. e.g. 30"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="12">
                <b-form-group label="Time Between Slots (in Minutes)">
                  <b-form-input
                    v-model="bulkSlot.timeBetweenSlots"
                    type="number"
                    min="1"
                    max="60"
                    placeholder="Time between each slot. e.g. 5"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Start Time">
                  <v-date-picker
                    v-model="bulkSlot.begindatetimestamp"
                    mode="time"
                    :minute-increment="5"
                    color="purple"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="End Time">
                  <v-date-picker
                    v-model="bulkSlot.enddatetimestamp"
                    mode="time"
                    :minute-increment="5"
                    color="purple"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="auto"
                class="my-auto"
              >
                <b-button
                  variant="primary"
                  @click="addBulkSlot"
                >
                  <feather-icon icon="PlusIcon" />
                  Add
                </b-button>
              </b-col>
            </b-row>
          </app-collapse-item>
        </app-collapse></b-col>
      <b-col class="border rounded py-1">
        <h4>Slots for the day</h4>
        <p v-if="!slotsByDate.length">
          No slots scheduled for the day!
        </p>
        <b-button-group
          v-for="(slot, index) in slotsByDate"
          :key="index"
          class="my-50"
        >
          <b-button
            variant="outline-primary"
          >

            {{ $moment(slot.begindatetimestamp).format("h:mm a") }} - {{ $moment(slot.enddatetimestamp).format("h:mm a") }}
            <span
              v-if="!slot.id"
              v-b-tooltip="'This slot is not saved yet'"
              class="text-danger mr-50"
            >*</span>
            <b-badge variant="primary">
              {{ $moment.utc($moment(slot.enddatetimestamp).diff($moment(slot.begindatetimestamp))).format('H[h] mm[m]') }}

            </b-badge>

          </b-button>
          <b-button
            variant="outline-danger"
            @click="deleteSlot(slot.begindatetimestamp, slot.enddatetimestamp)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button></b-button-group>
      </b-col>
    </b-row>
  </b-container>

</template>

<script>

import {
  BBadge,
  BButtonGroup, BContainer, BFormDatepicker, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  name: 'AddSlot',
  components: {
    BFormGroup,
    BFormDatepicker,
    BContainer,
    BButtonGroup,
    BFormInput,
    AppCollapse,
    AppCollapseItem,
    BBadge,
  },
  props: {
    existingSlots: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      slots: [...this.existingSlots],
      begindatetimestamp: null,
      enddatetimestamp: null,
      date: null,
      maxDate: null,
      minDate: new Date(),
      locale: 'en',
      disabledDates: {
        to: new Date(new Date().setDate(new Date().getDate() - 1)),
      },
      bulkSlot: {
        begindatetimestamp: null,
        enddatetimestamp: null,
        duration: null,
        timeBetweenSlots: null,
      },
    }
  },
  computed: {
    slotsByDate() {
      // Filter Slots by Date
      const slots = this.slots.filter(slot => new Date(slot.begindatetimestamp).toDateString() === this.date.toDateString())
      // Sort Slots by Start Time by using moment
      return slots.sort((a, b) => this.$moment(a.begindatetimestamp).diff(this.$moment(b.begindatetimestamp)))
    },
  },
  watch: {
    date(newDate, oldDate) {
      if (newDate !== oldDate) {
        this.begindatetimestamp = new Date(this.date)
        this.enddatetimestamp = new Date(this.date)
        this.bulkSlot.begindatetimestamp = new Date(this.date)
        this.bulkSlot.enddatetimestamp = new Date(this.date)
      }
    },
    slots: {
      handler(newSlots) {
        this.$emit('update-slots', newSlots)
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    checkSlotOverlap(oldSlot, newSlot) {
      const overlap = (newSlot.begindatetimestamp >= oldSlot.begindatetimestamp && newSlot.begindatetimestamp < oldSlot.enddatetimestamp) || (newSlot.enddatetimestamp > oldSlot.begindatetimestamp && newSlot.enddatetimestamp <= oldSlot.enddatetimestamp)
      if (overlap) {
        this.showAlert('Slot overlaps with an existing slot', 'warning')
        return true
      }
      return false
    },
    checkStartEnd(start, end) {
      if (start >= end) {
        this.showAlert('End Time should be greater than Start Time', 'warning')
        return true
      }
      return false
    },
    addSlot() {
      if (this.checkStartEnd(this.begindatetimestamp, this.enddatetimestamp)) {
        return
      }
      if (this.begindatetimestamp && this.enddatetimestamp) {
        const newSlot = {
          begindatetimestamp: this.$moment(this.begindatetimestamp).toISOString(),
          enddatetimestamp: this.$moment(this.enddatetimestamp).toISOString(),
        }
        if (this.slots.length) {
          const overlap = this.slots.some(slot => this.checkSlotOverlap(slot, newSlot))
          if (overlap) {
            return
          }
        }
        this.slots.push(newSlot)
      }
    },
    addBulkSlot() {
      if (this.checkStartEnd(this.bulkSlot.begindatetimestamp, this.bulkSlot.enddatetimestamp)) {
        return
      }
      // Check if duration is higher than difference between start and end time
      if (this.bulkSlot.duration > (this.bulkSlot.enddatetimestamp - this.bulkSlot.begindatetimestamp) / 60000) {
        this.showAlert('Duration should be less than the difference between Start Time and End Time', 'warning')
        return
      }

      if (this.bulkSlot.begindatetimestamp && this.bulkSlot.enddatetimestamp && this.bulkSlot.duration && this.bulkSlot.timeBetweenSlots) {
        const duration = this.bulkSlot.duration * 60000
        const timeBetweenSlots = this.bulkSlot.timeBetweenSlots * 60000
        const bulkSlotStartTime = this.bulkSlot.begindatetimestamp.getTime()
        const bulkSlotEndTime = this.bulkSlot.enddatetimestamp.getTime()
        const slots = []
        for (let i = bulkSlotStartTime; i < bulkSlotEndTime; i += duration + timeBetweenSlots) {
          const newSlot = {
            begindatetimestamp: this.$moment(i).toISOString(),
            enddatetimestamp: this.$moment(i + duration).toISOString(),
          }
          if (this.slots.length) {
            const overlap = this.slots.some(slot => this.checkSlotOverlap(slot, newSlot))
            if (!overlap) {
              slots.push(newSlot)
            }
          }
        }
        this.slots.push(...slots)
      }
    },
    deleteSlot(start, end) {
      this.slots = this.slots.filter(slot => slot.begindatetimestamp !== start && slot.enddatetimestamp !== end)
    },
    dateClass(date) {
      const dataHasSlots = this.slots.find(slot => new Date(slot.begindatetimestamp).toDateString() === new Date(date).toDateString())
      if (dataHasSlots) {
        return 'bg-light-success'
      }
      return ''
    },
  },
}
</script>
