<template>
  <b-container>
    <b-row>
      <b-col md="12">
        <b-form-group
          label="Select Entity Type"
          label-for="selectEntityType"
        >
          <v-select
            id="selectEntityType"
            v-model="entityType"
            :options="['Startup', 'Partner', 'Mentor']"
            placeholder="Select Entity Type"
            @input="selectedEntityId = ''"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="entityType">
      <b-col>
        <b-form-group
          :label="`Select ${entityType}`"
          label-for="selectEntity"
        >
          <v-select
            id="selectEntity"
            v-model="selectedEntityId"
            :get-option-label="option => ( option.title || option.full_name )"
            :options="getEntities()"
            :reduce="option => option.id"
            :placeholder="`Select ${entityType}`"
            @input="getAvailableSlots"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <!-- Choose Slots -->
    <b-row v-if="selectedEntityId">
      <b-col>

        <b-skeleton
          v-if="$apollo.loading"
          class="mt-1"
        />
        <b-tabs
          v-else-if="slots.length > 0"
          pills
        >
          <b-tab
            v-for="(day, index) in slotsByDate"
            :key="index"
          >
            <template #title>
              <p class="text-center mb-0">
                <span class="font-small-2">
                  {{ $moment(index).format('ddd') }}
                </span><br>
                {{ $moment(index).format('MMM d') }}
              </p>
            </template>
            <b-form-radio
              v-for="slot in day"
              :key="slot.start"
              v-model="selectedSlotId"
              button-variant="outline-primary"
              button
              class="m-50"
              :value="slot"
            >
              {{ $moment(slot.begindatetimestamp).format("h:mm a") }} - {{ $moment(slot.enddatetimestamp).format("h:mm a") }}
              <b-badge variant="primary">
                {{ $moment.utc($moment(slot.enddatetimestamp).diff($moment(slot.begindatetimestamp))).format('H[h] mm[m]') }}

              </b-badge>
            </b-form-radio>

          </b-tab>
        </b-tabs>
        <b-alert
          v-else
          variant="warning"
        >
          <div class="alert-body">
            <span>No Slots available.</span>
          </div>
        </b-alert>
      </b-col>
    </b-row>
    <b-row
      v-if="selectedSlotId"
      class="mt-1"
    >
      <b-col md="12">
        <b-form-group
          label="Title"
          label-for="title"
        >
          <b-form-input
            id="title"
            v-model="title"
            placeholder="Meeting title"
          />
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          label="Description"
          label-for="description"
        >
          <b-form-textarea
            id="description"
            v-model="description"
            placeholder="Enter Description"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>

import {
  BAlert,
  BBadge,
  BCol, BContainer, BFormGroup, BFormInput, BFormRadio, BFormTextarea, BRow, BSkeleton, BTab, BTabs,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import gql from 'graphql-tag'
// import gql from 'graphql-tag'

export default {
  name: 'BookSlots',
  components: {
    BAlert,
    BBadge,
    BRow,
    vSelect,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BContainer,
    BTabs,
    BTab,
    BFormRadio,
    BSkeleton,
  },
  props: {
    associations: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      entityType: '',
      startupType: '',
      selectedEntityId: '',
      choiceslotdate: '',
      slots: [],
      selectedSlotId: '',
      title: '',
      description: '',
    }
  },
  computed: {
    slotsByDate() {
      return this.slots.reduce((acc, slot) => {
        const date = this.$moment(slot.begindatetimestamp).format('YYYY-MM-DD')
        if (!acc[date]) {
          acc[date] = []
        }
        acc[date].push(slot)
        return acc
      }, {})
    },
  },
  methods: {
    getEntities() {
      if (this.entityType === 'Startup') {
        return this.associations.filter(e => e.type === 'startup')
      }
      if (this.entityType === 'Partner') {
        return this.associations.filter(e => e.type === 'partner')
      }
      if (this.entityType === 'Mentor') {
        return this.associations.filter(e => e.type === 'mentor')
      }
      return []
    },
    // @TODO: Add filter for start date greater than today
    getAvailableSlotsQuery(entityType) {
      if (entityType === 'Startup') {
        return gql`
          query getAvailableSlotsQuery($id: Int) {
            sessions_slots_table (where: { startup_organization_id: { _eq: $id } }) {
              id
              begindatetimestamp
              enddatetimestamp
            }
          }
        `
      }
      if (entityType === 'Partner') {
        return gql`
          query getAvailableSlotsQuery($id: Int) {
            sessions_slots_table (where: { partner_organization_id: { _eq: $id } }) {
              id
              begindatetimestamp
              enddatetimestamp
            }
          }
        `
      }
      if (entityType === 'Mentor') {
        return gql`
          query getAvailableSlotsQuery($id: Int){
            sessions_slots_table (where: { mentor_user_id: { _eq: $id } }) {
              id
              begindatetimestamp
              enddatetimestamp
            }
          }
        `
      }
      return false
    },
    getAvailableSlots() {
      const query = this.getAvailableSlotsQuery(this.entityType)
      this.$apollo.query({
        query,
        variables: {
          id: this.selectedEntityId,
        },
      }).then(response => {
        this.slots = response.data.sessions_slots_table
      })
    },
  },
}
</script>
